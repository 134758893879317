export const helpers = {
    // OPEN POPUP
    openPopup: (el) => {
        $.magnificPopup.open({
            items: {
                src: el,
                type: 'inline',
                midClick: true,
                closeOnBgClick: false
            },
            removalDelay: 500,
            mainClass: 'mfp-fade',
        });
    },

    // SHOW ERRORS
    showError: (message, title = window._TRANSLATION.views.components.error) => {
        $(`#message-modal .popup__head`).html(title);
        $(`#message-modal .messages`).empty();
        let errors = message;
        $(`#message-modal .messages`).append('<div><ul class="ul-clear">');

        let values = errors.error != null ? errors.error : errors;

        if (Array.isArray(values)) {
            $.each(values, function (key, item) {
                $(`#message-modal .messages ul`).append(`<li class="text-error">${item}</li>`);
            });
        } else {
            $(`#message-modal .messages ul`).append(`<li class="text-error">${errors}</li>`);
        }

        $(`#message-modal .messages`).append('</ul></div>');

        helpers.openPopup('#message-modal');
    },

    // SHOW MESSAGES
    showMessage: (message) => {
        $(`#message-modal .popup__head`).html(window._TRANSLATION.views.components.message);
        $(`#message-modal .messages`).empty();
        $(`#message-modal .messages`).html(`<p class="text-success">${message}</p>`);

        helpers.openPopup('#message-modal');
    },

    // Search in array of objects
    searchInObject: (arr, key, value) => {
        let [index, items] = [null, null];

        items = arr.filter( function(item, _index) {
            if( item[key] == value ) {
                index = _index;
                return item;
            }
        } );

        return {index, items};
    },

    // Select2 Format
    formatState: (state) => {
        const option = $(state.element);
        const color = option.data("color");

        if (!color) {
            return state.text;
        }

        return $(`<span style="color: ${color}">${state.text}</span>`);
    },
    // Get currency short name
    currencyShortName: (lang) => {
        switch(lang){
            case 'en':
                return 'usd';
            case 'ru':
                return 'руб';
            case 'ua':
                return 'грн';
            default:
                return 'usd';
        }
    },
    // Get key by value in object
    getKeyByValue: (object, value, key = null) => {
        // If no key present, search in one-dimension
        if(key == null){
            for (let prop in object) {
                if (object.hasOwnProperty(prop)) {
                    if (object[prop] === value)
                        return prop;
                }
            }
        } else {
            for (const [k, v] of Object.entries(object)) {
                if (v.hasOwnProperty(key)) {
                    if (v[key] === value)
                        return {
                            key: k,
                            value: v[key]
                        };
                }
            }
        }

        return null;
    },
    // Increment balance
    changeBalance: (amount, selector = '.balance-amount') => {
        $(selector).html( parseFloat(amount).toFixed(2) );
    },
    // Paginate
    paginate: (selector, callback, data = {}) => {
        let aSelector = $(selector).find('a');
        if(aSelector != null){

            let link = aSelector.attr('href');
            $.get(link, data,
                function (data, textStatus, jqXHR) {
                    if( data.error != null ){
                        helpers.showError(data.error);
                        return;
                    }
                    callback(data);
                }
            );
        }
    },
    /** Get last property in object */
    getLastKey: (object) => {
        let last = null;
        for (const [key, value] of Object.entries(object)) {
            last = key;
        }
        return last;
    },
    /** Copy to clipboard */
    copy: (id) => {
        let copyText = document.getElementById(id);
        if(copyText == null) return;

        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        let input = document.createElement('input');
        input.setAttribute('value', copyText.value);
        document.body.appendChild(input);
        input.select();
        let result = document.execCommand('copy');
        document.body.removeChild(input);

        return result;
    },
    // Translate
    translate: (message) => {
        if(typeof message === 'undefined' || message == null) return '??';

        return message;
    },
    // Convert Rate
    convertRate: (rates, amount, from, to) => {
        from = from.toLowerCase();
        to = to.toLowerCase();

        let calculated = null;

        if(from == to) return amount;

        // USD -> OTHER
        if( from == 'usd' && to != 'usd' ) {
            let rate = rates.find(obj => {
                return obj.currency.name == to;
            });
            if(rate == null) return null;
            calculated = rate.rate * amount;
        // OTHER -> USD
        } else if( from != 'usd' && to == 'usd') {
            let rate = rates.find(obj => {
                return obj.currency.name == from;
            });
            if(rate == null) return null;
            calculated = amount / rate.rate;
        // OTHER -> OTHER
        } else {
            let rateFrom = rates.find(obj => {
                return obj.currency.name == from;
            });
            if(rateFrom == null) return null;

            let rateTo = rates.find(obj => {
                return obj.currency.name == to;
            });
            if(rateTo == null) return null;

            calculated = rateTo.rate / rateFrom.rate * amount;
        }

        return (calculated != null) ? Math.round( (calculated + Number.EPSILON) * 100) / 100 : calculated;
    },
    // Set Cookie
    setCookie: (cname, cvalue, exdays) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    // Get Cookie
    getCookie: (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    },
    // Display timer
    startTimer: (duration, display) => {
        let timer = duration, minutes, seconds;

        let interval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            display.text(minutes + ":" + seconds);

            if (--timer < 0) {
                display.text("");
                clearInterval(interval);
                timer = duration;
            }
        }, 1000);
    }
}
