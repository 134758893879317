import.meta.glob([
    '../images/**',
]);

import './bootstrap';

import $ from 'jquery';
window.jQuery = window.$ = $;

//import 'jquery-ui/dist/jquery-ui.min.js';
import 'jquery-ui/dist/jquery-ui';
//import 'magnific-popup/dist/jquery.magnific-popup';
import 'magnific-popup';
// On production

//import 'select2';
//import 'easy-pie-chart';

// DEV
import select2 from 'select2';
select2();
import easyPieChart from 'easy-pie-chart';
window.easyPieChart = easyPieChart;

// My custom helpers
import { helpers } from './helpers';
window.helpers = helpers;

// Set CSRF token
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('[name="_token"]').val(),
        'X-GLOBAL-LANGUAGE': $('html').attr('lang')
    }
});
